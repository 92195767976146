<template>
    <div class="user_policy">
        <iframe :src="path" frameborder="0"></iframe>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                path: this.$route.query.path
            }
        },
        mounted() {
            
        },
    }
</script>
<style>
    .user_policy {
        width: 100vw;
        height: 100vh;
    }

    .user_policy iframe {
        width: 100%;
        height: 100%;
    }
</style>